import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_7Questions = () => {
    const seo = {
        metaDesc: 'Ask yourself these 7 questions to determine whether you should get a patent! - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Ask yourself these 7 questions to determine whether you should get a patent! - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>Ask yourself these 7 questions to determine whether you should get a patent!</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <p><strong><em>1.&nbsp; Is it commercializable?</em></strong></p>
                                <p>Look at the cost of your invention, competitors’ products, the invention’s ease of use, and consumer demand.
                                </p>
                                <p><strong><em>2.&nbsp; Did I invent it?</em></strong></p>
                                <p>You can only obtain a patent if you personally<br /> invented something. The inventor is the initial patent
                                    rights owner.&nbsp;</p>
                                <p>You may also be a co-inventor to file. &nbsp;A co-inventor is someone who contributed to at least one novel
                                    and non-obvious concept that makes the invention patentable.&nbsp; This will be further discussed later.
                                </p>
                                <p><strong><em>3.&nbsp; Do I own it?</em></strong></p>
                                <p>You must own the invention to file a patent application. This may not be the case if your employer owns the
                                    rights to the invention, i.e. you’ve given up rights to the invention prior to its creation, you were
                                    hired specifically to invent it, or your employer has certain rights to use the invention.</p>
                                <p><strong><em>4. Is it<br /> useful?</em></strong></p>
                                <p>A patent is <em>only</em> granted for useful<br /> inventions. Though most inventions are useful, the USPTO has
                                    found the<br /> following to be ineligible for patents:</p>
                                <p></p>
                                <ul>
                                    <li>ornamental, without utility. You may want to<br /> consider a design patent application if your
                                        invention has visual ornamental<br /> characteristics embodied in or applied to an article of
                                        manufacture that is not<br /> functionally useful;</li>
                                    <li>unsafe drugs;</li>
                                    <li>nuclear weapons;</li>
                                    <li>immoral inventions;</li>
                                    <li>non-operable inventions;</li>
                                    <li>inventions only with illegal uses; and</li>
                                    <li>theoretical phenomenon.</li>
                                </ul>
                                <p><strong><em>5.&nbsp; Does it fit into a patent “class”?</em></strong></p>
                                <p>The U.S. Supreme Court said that anything man-made falls into these “classes,” but anything natural or
                                    abstract will not.&nbsp; The “classes” are broad, so an invention is likely to fit into one of these
                                    categories.&nbsp; In fact, it might fit into a couple.&nbsp; Ultimately, for a patent to issue, an
                                    invention <em>must</em> fall into at least one of them.&nbsp; The following are the five “classes.” What
                                    are these fives classes?</p>
                                <p><strong><em>6.&nbsp; Is it novel?</em></strong></p>
                                <p>Patented inventions <em>must</em> be different from existing knowledge or previous inventions, otherwise
                                    known as prior art. That means the new invention should be physically or operationally unique in at
                                    least one way from the date it was conceived, otherwise known as the date of invention or the date you
                                    filed a patent application.&nbsp; Novelty includes the invention incorporating a new feature, using an
                                    old feature in a new way, or having a new combination of old features. <a href="http://inventiv.org">How
                                        to know if your invention is novel?</a></p>
                                <p><strong><em>7.&nbsp; Is it non-obvious?</em></strong></p>
                                <p>This is the highest bar to patent<br /> ownership.&nbsp; If something is obvious, then<br /> it isn’t
                                    patentable.&nbsp; If it is surprising<br /> and unexpected, then it usually is non-obvious and hence, may
                                    be patentable.</p>
                                <p>Factors in determining obviousness include:</p>
                                <p></p>
                                <ul>
                                    <li>invention has commercial success;<ul>
                                        <li>invention solves a non-obvious problem;</li>
                                    </ul>
                                        <ul>
                                            <li>invention subtracts a piece of hardware that was<br /> included in the prior art;</li>
                                        </ul>
                                        <ul>
                                            <li>invention modifies the prior art in a new way;</li>
                                        </ul>
                                        <ul>
                                            <li>industry needs the invention;</li>
                                        </ul>
                                        <ul>
                                            <li>others have tried to come up with this invention<br /> but failed;</li>
                                        </ul>
                                        <ul>
                                            <li>other inventors said this invention was<br /> impossible;</li>
                                        </ul>
                                        <ul>
                                            <li>others have copied this invention; or</li>
                                        </ul>
                                        <ul>
                                            <li>others in the field have praised the invention.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>You can read more about the USPTO’s process for determining obviousness at
                                    http://www.uspto.gov/web/offices/pac/mpep/documents/2100_2141.htm.</p>
                                <p>Briefly, the focus for the USPTO when making a<br /> determination of obviousness is—what a person of ordinary
                                    skill in the<br /> pertinent art would have known at the time of the invention, and on what such a<br />
                                    person would have reasonably expected to have been able to do in light of that<br /> knowledge. This is
                                    the standard regardless of whether the source of that knowledge<br /> and ability was documented prior
                                    art, general knowledge in the art or common<br /> sense.&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_7Questions;